import React, { useEffect, useState } from 'react'
import moment from 'moment'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'

import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import Button from '../../../components/Button'
import DatePicker from 'react-datepicker'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import currencyFormat from '../../../helpers/currencyFormat'
import { history } from '../../../helpers/history'
import convertDate from '../../../helpers/convertDate'
import Loading from '../../../components/Loading'
import withdrawSevices from '../../../services/withdraw'

import './style.scss'
import Table from '../../../components/Table'
import TagItem from '../../../components/TagItem'
import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react'
import { formatStringByMask } from '../../../helpers/formatStringByMask'
import { CheckPicker, DateRangePicker, Drawer, Input, MaskedInput } from 'rsuite'
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from 'date-fns'
import { orderPayment, orderStatus } from '../Orders'
import orderService from '../../../services/orders'
import { CurrencyExchange, FilterAltOff } from '@mui/icons-material'
import DrawerFilters from '../../../components/DrawerFilters'
import { useParams } from 'react-router-dom'
import { Money } from '@material-ui/icons'

const _status = [
	{ 'raw': 'pending', 'label': 'Pendente', color: '#D8C364' },
	{
		'raw': 'sented', 'label': 'Enviado', color: '#209869',
	}, {
		'raw': 'requested', 'label': 'Solicitado', color: '#D8C364',
	}, {
		'raw': 'paid_out', 'label': 'Pago', color: '#209869',
	}, {
		'raw': 'canceled', 'label': 'Cancelado', color: '#D86470',
	}, {
		'raw': 'completed', 'label': 'Completo', color: '#209869',
	}, {
		'raw': 'received', 'label': 'Recebido pela processadora', color: '#209869',
	}, {
		'raw': 'processing', 'label': 'Em processamento', color: '#4E9DCB',
	}, {
		'raw': 'paid', 'label': 'Pago', color: '#209869',
	}, {
		'raw': 'rejected', 'label': 'Rejeitado', color: '#D86470',
	}, {
		'raw': 'inreview', 'label': 'Em Revisão', color: '#4E9DCB',
	}, {
		'raw': 'awaiting', 'label': 'Aguardando', color: '#D8C364',
	}, {
		'raw': 'processed', 'label': 'Processado', color: '#209869',
	}, {
		'raw': 'reversed', 'label': 'Reembolsado', color: '#D86470',
	}, {
		'raw': 'invalid_data_input', 'label': 'Dados inválidos', color: '#D86470',
	}, {
		'raw': 'error', 'label': 'Erro', color: '#D86470',
	}]

const _filter = [
	{ "value": 'paid', "label": 'Pago' },
	{ "value": 'reversed', "label": 'Reembolsado' },
	{ "value": 'requested', "label": 'Solicitado' },
	{ "value": 'sented', "label": 'Enviado' },
	{ "value": 'pending', "label": 'Pendente' },
	// { 'option': 'canceled', 'label': 'Cancelado' },
	// { 'option': 'completed', 'label': 'Completo' },
	// { 'option': 'received', 'label': 'Recebido' },
	// { 'option': 'processing', 'label': 'Em processamento' },
	// { 'option': 'rejected', 'label': 'Rejeitado' },
	// { 'option': 'inreview', 'label': 'Em Revisão' },
	// { 'option': 'awaiting', 'label': 'Aguardando' },
	// { 'option': 'processed', 'label': 'Processado' },
	// { 'option': 'invalid_data_input', 'label': 'Dados inválidos' },
	// { 'option': 'error', 'label': 'Erro' }
]

const _type = [
	{ 'type': 'C/C', 'label': 'Conta Corrente' }, { 'type': 'C/I', 'label': 'Conta Poupança' }, { 'type': 'C/P', 'label': 'Conta Pagamento' }]

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
const phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]

const predefinedPastRanges = [
	{
		label: 'Hoje', value: [new Date(), new Date()], placement: 'left',
	}, {
		label: 'Ontem', value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left',
	}, {
		label: 'Essa semana', value: [startOfWeek(new Date()), endOfWeek(new Date())], placement: 'left',
	}, {
		label: 'Últimos 7 dias', value: [subDays(new Date(), 6), new Date()], placement: 'left',
	}, {
		label: 'Últimos 30 dias', value: [subDays(new Date(), 29), new Date()], placement: 'left',
	}, {
		label: 'Esse mês', value: [startOfMonth(new Date()), new Date()], placement: 'left',
	}, {
		label: 'Mês passado', value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left',
	}]


function ConfirmationDialog({children, title, message, onConfirm}) {
  const [open, setOpen] = useState(false)
  
  function handleConfirm() {
    setOpen(false)
    onConfirm()
  }

  return (
    <>
      <Dialog open={open} onClose={setOpen} className="confirmation">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <span className="link" onClick={() => setOpen(false)}>Cancelar</span>
          <span className="link" onClick={handleConfirm}>Confirmar</span>
        </DialogActions>
      </Dialog>

      <button type="button" onClick={() => setOpen(true)}>
        {children}
      </button>
    </>
  )
}

const Withdrawals = (props) => {
	function selectableCheckbox (status) {
		return status === 'requested'
	}

	const [withdrawals, setWithdrawals] = useState({})

	const [loading, setLoading] = useState(false)
	const [confirm, setConfirm] = useState(false)

	const [withdrawId, setWithdrawId] = useState(0)
	const [alert, setAlert] = useState({ show: false, severity: 'info', message: '' })

	const [isChecked, setChecked] = useState([])
	const [isAllChecked, setAllChecked] = useState(false)
	const [sending, setSending] = useState(false)

	const [openDrawerFilters, setOpenDrawerFilters] = useState(false)
	const [filters, setFilters] = useState({})

	useEffect(() => {
		getTableData()
	}, [])

	const getTableData = async (reset = false) => {
		setLoading(true)

		let data = await withdrawSevices.getWithdrawals({
			page: 1,

			...(!reset && getFilters()),
		})

		if (!data) {
			setLoading(false)
			setWithdrawals([])
			return
		}

		data = filter_confirmed_withdraws(data)
		setWithdrawals(data)
		setLoading(false)
	}

	const handleConfirm = () => {
		setConfirm(!confirm)
	}

	useEffect(() => {
		hideSelectAllCheckbox()
	}, [withdrawals])

	const doneWithdraw = async () => {
		if (!withdrawId) return

		setConfirm(false)
		setLoading(true)

		const response = await withdrawSevices.doneWithdraw({ id: withdrawId, status: 'paid_out' })
		if (response) {
			getTableData()
			setAlert({ show: true, severity: 'success', message: 'Saque baixado com sucesso!' })
			setTimeout(() => {
				setAlert({ show: false, severity: 'warning', message: '' })
			}, 5000)
		} else {
			setAlert({ show: true, severity: 'warning', message: 'Falha ao baixar esse saque.' })
			setTimeout(() => {
				setAlert({ show: false, severity: 'warning', message: '' })
			}, 5000)
		}
		setLoading(false)
	}

	const filter_confirmed_withdraws = (data) => {
		const formated_result = []
		let counter = data.pagination.total

		data.result.map((item) => {
			if (item.confirmed) {
				formated_result.push(item)
			} else {
				counter = counter - 1
			}
		})
		data.result = formated_result
		data.pagination.total = counter

		return data
	}

	const handleSelect = (e) => {
		const { id, checked } = e.target
		if (checked) {
			setChecked([...isChecked, parseInt(id)])
		} else {
			setChecked(isChecked.filter(item => item !== parseInt(id)))
		}
	}

	const handleSelectAll = () => {
		if (isAllChecked) {
			setChecked([])
		} else {
			const filteredWithdraws = withdrawals?.result?.filter((item) => {
				if (selectableCheckbox(item.status)) {
					return true
				} else {
					return false
				}
			})

			setChecked(filteredWithdraws.map(li => li.id))
		}
		setAllChecked(!isAllChecked)
	}

	const handleProcessBatch = async () => {
		setSending(true)

		const formated = isChecked.map((item, index) => {
			return item.toString()
		})

		const retorno = await withdrawSevices.processWithdraws(formated)

		if (retorno && retorno.data.data.msg.includes('successfully')) {
			setAlert({ show: true, severity: 'success', message: 'Lote enviado para processamento!' })
			setTimeout(() => {
				setAlert({ show: false, severity: 'success', message: '' })
			}, 5000)
			setSending(false)
			onSubmitFilters(true)
			setChecked([])
			setAllChecked(false)
		} else {
			setAlert({ show: true, severity: 'warning', message: 'Ocorreu um erro ao processar o lote!' })
			setTimeout(() => {
				setAlert({ show: false, severity: 'success', message: '' })
			}, 5000)
			setSending(false)
			onSubmitFilters()
			setChecked([])
			setAllChecked(false)
		}
	}

	function hideSelectAllCheckbox () {
		const hide = withdrawals?.result?.length > 0 && withdrawals?.result?.every((item) => !selectableCheckbox(item?.status))

		return hide
	}

	const paginate = async (page) => {
		setLoading(true)
		const payload = {
			page,
			...getFilters(),
		}

		await withdrawSevices.getWithdrawals(payload).then(data => {
			if (!data) return setLoading(false)

			setWithdrawals(data)
		}).finally(() => setLoading(false))
	}

	async function onSubmitFilters(reset = false) {
		if (reset) {
			await setFilters({})
		}

		setOpenDrawerFilters(false)
		await getTableData(reset)
	}

	const filterCount = Object.entries(getFilters()).length

	function getFilters() {
		const activeFilters = {}

		Object.entries(filters).filter(([key, value]) => {
			const currentValue = value?.formatted || value.raw
			if (!currentValue || currentValue === '' || currentValue.length === 0) return false
			return true
		}).map(([key, value]) => {
			activeFilters[key] = value?.formatted || value.raw
		})

		return activeFilters
	}
  
  async function handleManualProcess(row) {
    await withdrawSevices.processManualWithdraw(row?.id)
    await paginate(withdrawals?.pagination?.current)
  }

	return (
		<main id="withdraw">
		<div id="title">
			<span className="back" onClick={event => history.back()}><ArrowBackIosIcon/> <span>Voltar</span></span>
			<h1>Solicitações de saques</h1>
		</div>

		<DrawerFilters
			open={openDrawerFilters}
			setOpen={setOpenDrawerFilters}
			onSubmit={() => onSubmitFilters()}
			filters={[
				{
					label: "Email",
					name: "customer_email",
					field: () => (
						<Input
							id="input-email"
							value={filters?.customer_email?.raw}
							onChange={(value) =>
								setFilters((state) => ({
										...state,
										customer_email: {
											raw: value,
										},
									})
								)}
							placeholder="Email do cliente"
							type='email' className="w-full"
						/>
					),
				},
				{
					label: "Status",
					name: "status",
					field: () => (
						<CheckPicker
							autoComplete="false"
							id="input-status"
							value={filters?.status?.raw}
							onChange={(value) =>
								setFilters((state) => ({
									...state,
									status: {
										raw: value,
									},
								})
							)}
							placeholder="Escolha um status"
							searchable={false}
							sticky
							className={'w-full'}
							data={_filter}
						/>
					),
				},
				{
					label: "CPF",
					name: "customer_cpf",
					field: () => (
						<MaskedInput
							id="input-cpf"
							value={filters?.customer_cpf?.raw}
							onChange={(value) =>
								setFilters((state) => ({
										...state,
										customer_cpf: {
											raw: value,
											formatted: value?.replace(/\D/g, ''),
										},
									})
								)}
							placeholder="CPF do cliente"
							mask={cpfMask} className="w-full"
						/>
					),
				},
				{
					label: "Data de criação",
					name: "range_date",
					field: () => (
						<DateRangePicker
							placement="auto"
							id="input-period"
							value={filters?.range_date?.raw}
							format="dd/MM/yyyy"
							onChange={(value) => {
								setFilters((state) => {
									return ({
										...state,
										range_date: {
											raw: value,
											formatted: !value ? null : encodeURI([moment(value && value[0]).format('YYYY-MM-DD'), moment(value && value[1]).format('YYYY-MM-DD')]),
										},
									})
								})
							}}
							showOneCalendar
							ranges={predefinedPastRanges}
							placeholder="Escolha um período"
							className='w-full'
						/>
					),
				},
				{
					label: "Telefone",
					name: "phone",
					field: () => (
						<MaskedInput
							id="input-phone"
							value={filters?.customer_phone?.raw}
							onChange={(value) =>
								setFilters((state) => ({
										...state,
										customer_phone: {
											raw: value,
											formatted: value?.replace(/\D/g, ''),
										},
									})
								)}
							placeholder="Telefone do cliente"
							mask={phoneMask} className="w-full"
						/>
					),
				},
			]}
		/>

		<div id="infos">
			<span>Total de <strong>{withdrawals?.pagination?.total}</strong> registros encontrados.</span>
		</div>

		<div className="flex items-center justify-end flex-1 gap-4">
			<div className="flex items-center gap-2">
				{filterCount > 0 && (<FilterAltOff onClick={() => onSubmitFilters(true)} style={{ cursor: 'pointer', fill: '#209869' }}/>)}
				<Button
					small
					color="green"
					action={setOpenDrawerFilters}
					label={`Aplicar filtros ${filterCount > 0 ? `(${filterCount})` : ''}`}
					disabled={loading}
				/>
			</div>

			<Button small disabled={sending} type="button" color="green" label={`Processar lote (${isChecked.length})`} action={event => handleProcessBatch()}/>
		</div>


		<div id="list">

			<Collapse className="feedback" in={alert.show}>
				<Alert severity={alert.severity}>{alert.message}</Alert>
			</Collapse><br/>

			<Table
				loading={loading}
				rows={withdrawals?.result}
				pagination={withdrawals?.pagination}
				paginate={paginate}
				renderRow={(row) => [
					{
						headerName: <input type="checkbox" hidden={hideSelectAllCheckbox()} onChange={handleSelectAll} checked={isAllChecked}/>,
						render: () => (<input id={row.id} hidden={!selectableCheckbox(row.status)} type="checkbox" onChange={handleSelect} checked={isChecked.includes(row.id)}/>),
					},
					{ headerName: 'Solicitação', field: 'id' },
					{ headerName: 'Valor', render: () => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.amount) },
					{ headerName: 'Cliente', render: () => (`${[row.user.first_name, row.user.last_name].join(' ')}`) },
					{ headerName: 'CPF', render: () => formatStringByMask('XXX.XXX.XXX-XX', row.user.cpf) },
					{ headerName: 'Telefone', render: () => formatStringByMask("(XX) XXXXX-XXXX", row.user.phone) },
					{ headerName: 'Data solicitação', render: () => moment(row.created_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') },
					{ headerName: 'Data atualização', render: () => moment(row.updated_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') },
					{
						headerName: 'Status', render: () => {
							const status = _status?.filter(data => row.status === data.raw)[0]
							return <TagItem item={{ color: status?.color, label: status?.label }}/>
						},
					},
					{
						headerName: 'Ações', render: () => (
              <div className="flex items-center gap-2">
                <Popover placement="top" showArrow={true}>
                  <PopoverTrigger>
                    <Tooltip title="Dados bancários" aria-label="bankData"><AccountBalanceIcon className="link"/></Tooltip>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="p-2 flex flex-col">
                      <div>
                        <strong>CPF:</strong>
                        {' '}
                        <span>{row.bank_account?.document_number || ''}</span>
                      </div>
                      <div>
                        <strong>Banco:</strong>
                        {' '}
                        <span>{row.bank_account?.bank_institution || ''}</span>
                      </div>
                      <div>
                        <strong>Ag:</strong>
                        {' '}
                        <span>{row.bank_account?.account_agency || ''}</span>
                      </div>
                      <div>
                        <strong>CC:</strong>
                        {' '}
                        <span>{row.bank_account?.account_number || ''}</span>
                      </div>
                      <div>
                        <strong>Tipo:</strong>
                        {' '}
                        <span>{row.bank_account?.account_type ? _type.filter((item) => item.type === row.bank_account?.account_type)[0].label : ''}</span>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
                {selectableCheckbox(row.status) && (
                  <ConfirmationDialog
                    title="Confirmação"
                    message="Deseja realizar este saque manualmente?"
                    onConfirm={() => handleManualProcess(row)}
                  >
                    <CurrencyExchange className="link" />
                  </ConfirmationDialog>
                )}
              </div>
            ),
					}
				]}
			/>
		</div>

		<Dialog open={confirm} onClose={handleConfirm} className="confirmation">
			<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Confirmação</DialogTitle>
			<DialogContent>
				<DialogContentText>Você deseja fazer dar baixa nesse saque?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<span className="link" onClick={handleConfirm}>Cancel</span>
				<span className="link" onClick={doneWithdraw}>Sim!</span>
			</DialogActions>
		</Dialog>
	</main>)
}

export default Withdrawals
